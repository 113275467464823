import React, { useEffect, useRef } from 'react'
import BulletPoint from '../../components/common/utils/BulletPoint'
import './style.css'

const TermsAndConditions = () => {
  const topRef = useRef(null)
  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])
  return (
    <>
      <div class='top__header' ref={topRef}>
        <div class='container'>
          <h1>Terms of Use</h1>
        </div>
      </div>
      <div className='container'>
        <div className='terms__page'>
          <p>
            Welcome to the Safety Tech Innovation Network website. This website
            is part of the Safety Tech Innovation Network website. Use of this
            website is subject to the terms of use and privacy policy outlined
            below. If you do not agree to these terms of use, you should stop
            using this site.{' '}
          </p>
          <p>
            The Online Harms Taxonomy was delivered by the Online Safety Data
            Initiative (OSDI), which is led by a consortium of experts from the
            Department of Digital, Media, Culture and Sport (DCMS) and wider
            Government, the Online Safety Tech Industry Association (OSTIA),
            Faculty and PUBLIC. The Online Harms Taxonomy is part of the Safety
            Tech Innovation Network, which is a programme, delivered by KTN and
            sponsored by the Department of Digital, Media, Culture and Sport
            (DCMS) and Nominet. The safetytechnetwork.org.uk site is operated by
            KTN.
          </p>
          <p>
            {' '}
            These terms of use refer to our Privacy Policy, which also applies
            to your use of our Site, and which sets out the terms on which we
            may collect, store and process your personal data. By using our
            Site, you consent to such processing and you warrant that all data
            provided by you is accurate.
          </p>
          <p>
            <b> Changes to these terms</b>
          </p>
          <p>
            On accessing the Safety Tech Innovation Network website and its
            affiliated pages, you will be deemed to have accepted these terms
            and conditions. We reserve the right to alter these terms at any
            time and any changes will be posted online. Please check this page
            from time to time to take notice of any changes we made, as they are
            binding on you. Continued use of this website after changes are
            posted online shall constitute acceptance of the new terms of use.
          </p>
          <p>
            <b>Links to other websites </b>
          </p>
          <p>
            This website contains links to other web sites operated by parties
            other than Safety Tech Innovation Network. We do not control such
            websites and are not responsible for the content of these sites or
            the use being made of these sites. We do not warrant that this
            website will be available without interruption or that it is error,
            virus or bug free.
          </p>
          <p>
            <b>Availability of our Site</b>
          </p>
          <p>
            Availability of, and access to, our Site is provided to you on a
            temporary basis and we reserve the right to suspend, withdraw,
            discontinue or change all or any part of our Site, its functionality
            and its contents without notice at any time. We will not be liable
            to you, or any connected party, if, for any reason, our Site or the
            content it contains is unavailable for access at any time for any
            period.
          </p>
          <p>
            <b>Acceptable Uses of our Site</b>
          </p>
          <p>
            You may only use our Site for purposes which are lawful and comply
            with these terms and conditions of use. For the avoidance of doubt,
            you may not use our Site:
          </p>
          <ul style={{ marginLeft: 10 }}>
            <li>
              <BulletPoint item='in any way that breaches any applicable local, national or international law or regulation' />
            </li>
            <li>
              <BulletPoint item='in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect' />
            </li>
            <li>
              <BulletPoint item='to download, use or re-use any material or content which does not comply with our content standards' />
            </li>
            <li>
              <BulletPoint item='to knowingly transmit any data or material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any Device, computer software or hardware.' />
            </li>
          </ul>
          <p> You also agree:</p>
          <p>
            <ul style={{ marginLeft: 10 }}>
              <li>
                <BulletPoint item='not to reproduce, duplicate, copy or re-sell any part of our Site in contravention of the provisions of these terms and conditions of use.' />
                <li>
                  <BulletPoint item='not to access without authority, interfere with, damage or disrupt:' />
                </li>
                <ul>
                  <li>
                    <BulletPoint item='any part of our Site' />
                  </li>
                  <li>
                    <BulletPoint item='any equipment or network on which our Site is hosted' />
                  </li>
                  <li>
                    <BulletPoint
                      item='
  any software used in the provision of our Site; or any     equipment, network or software owned or used by any third     party in connection with the provision of our Site.   '
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <p>
            <b>Materials, information and content uploaded to our Site </b>
          </p>
          <p>
            You can provide feedback against any of the definitions on the
            Online Harms Taxonomy. However, by doing so, you grant us a
            worldwide, transferable, sub-licensable, royalty-free licence to
            use, copy, modify, distribute, publish, store and process all such
            materials, information and content, and make it available to third
            parties. The Online Harms Taxonomy definitions, exemplars and edge
            cases will regularly be updated, and the materials, information and
            content you provide may or may not be included in those changes.
          </p>
          <i>
            Elements of the website may not be compliant with Accessibility WCAG
            2.1 AA guidelines and that an audit is in progress
          </i>
        </div>
      </div>
    </>
  )
}

export default TermsAndConditions
