import React, { useEffect, useState } from 'react'
import backgroundImage from './../../assets/images/OSDI-OnLine-harm-taxonomy-knowledge-hub-homepage-main.png'
import './style.css'
import { useNavigate } from 'react-router-dom'
import ConsentForm from './ConsentForm'
import HomePageOverlay from './HomePageOverlay'
function Home({ consentIsGiven, setConsentIsGiven, returnAddress }) {
  let navigate = useNavigate()

  const [mobileMode, setMobileMode] = useState(window.innerWidth < 960 && true)
  const [showConsentForm, setShowConsentForm] = useState(true && returnAddress)
  const [acceptBtn, setAccept] = useState(true)

  useEffect(() => {
    window.innerWidth < 960 ? setMobileMode(true) : setMobileMode(false)
  }, [window.innerWidth])

  const handleClose = () => {
    setShowConsentForm(false)
  }
  const toggleAccept = () => {
    setAccept(!acceptBtn)
  }
  return (
    <>
      <div className='black__background '>
        <img
          src={backgroundImage}
          alt='OSDI-Knowledge-Hub'
          className='backgorund__image'
        />
        {!showConsentForm && (
          <HomePageOverlay
            {...{
              setShowConsentForm,
              navigate,
              consentIsGiven,
              mobileMode
            }}
          />
        )}
      </div>
      <ConsentForm
        {...{
          showConsentForm,
          handleClose,
          toggleAccept,
          acceptBtn,
          navigate,
          setConsentIsGiven,
          consentIsGiven,
          returnAddress,
          mobileMode,
        }}
      />
    </>
  )
}

export default Home
