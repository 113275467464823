import React from 'react'
import { MakeClassName } from '../../components/common/utils/index'

const HomePageOverlay = ({
  mobileMode,
  setShowConsentForm,
  navigate,
  consentIsGiven,
}) => {
  const handleViewTaxonomy = () => {
    consentIsGiven
      ? mobileMode
        ? navigate('/fullTaxonomyTable')
        : navigate('/tree')
      : setShowConsentForm(true)
  }
  debugger
  return (
    <div
      style={{
        height: '80vh',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div className='home_overlay_box'>
        <h1 className='top_title'>Welcome to the Online Harms Taxonomy</h1>
        <h2 className='sub_title'>
          An interactive resource to facilitate collaboration across the Safety
          Tech community, supporting the continued development of common
          definitions for harmful and illegal content and behaviour.
        </h2>
        <div
          className={MakeClassName('buttons_box ', mobileMode && ' fixed_box')}
        >
          <button
            type='buttons_box'
            className={MakeClassName(
              'home_button right_btn mx-auto',
              mobileMode && ' home_button right_btn'
            )}
            onClick={() => handleViewTaxonomy()}
          >
            <b>View the OSDI Taxonomy</b>
          </button>
          <button
            type='button'
            className='home_button mx-auto'
            onClick={() => navigate('/about-us')}
          >
            <b>About the OSDI Taxonomy</b>
          </button>
        </div>
      </div>
    </div>
  )
}
export default HomePageOverlay
