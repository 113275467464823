import React, { useState, useEffect } from 'react'
import data from '../../../../taxonomy.json'
import TaxonomyCard from '../card'

const HidenCardChildrens = (props) => {
  const { node, openModal, onpageChang } = props

  const [nodeChildren, setNodeChildren] = useState([])

  useEffect(() => {
    let childrenItems = data.filter(
      (dataItem) => dataItem['parent-id'] === node.id
    )
    setNodeChildren(childrenItems)
  }, [node])

  return (
    <>
      {nodeChildren.length > 0 && (
        <>
          {nodeChildren.map((item) => (
            <TaxonomyCard
              openModal={openModal}
              onpageChang={onpageChang}
              key={item.id}
              node={item}
            />
          ))}
        </>
      )}
    </>
  )
}

export default HidenCardChildrens
