import React from 'react'
import parser from 'html-react-parser'

import bullet from '../../../assets/images/blackdot.png'
const BulletPoint = ({ item }) => {
  return (
    <>
      <img
        src={bullet}
        alt='Bullet_Point'
        style={{ marginRight: '5px', width: '8px' }}
      />
      {parser(item)}
    </>
  )
}

export default BulletPoint
