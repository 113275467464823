import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'

const ConsentForm = ({
  showConsentForm,
  handleClose,
  toggleAccept,
  acceptBtn,
  navigate,
  setConsentIsGiven,
  consentIsGiven,
  returnAddress,
  mobileMode,
}) => {
  const handleAcceptConsent = () => {
    setConsentIsGiven(true)
    navigate(
      returnAddress
        ? returnAddress
        : mobileMode
        ? '/fulltaxonomytable'
        : '/tree'
    )
  }
  useEffect(() => {
    consentIsGiven &&
      navigate(
        returnAddress
          ? returnAddress
          : mobileMode
          ? '/fulltaxonomytable'
          : '/tree'
      )
  }, [returnAddress])

  return (
    <Modal
      show={showConsentForm}
      onHide={handleClose}
      size='lg'
      className='consent__modal'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <p>
          <p>
            <strong>CONTENT WARNING:</strong> This website contains several
            synthetically generated examples of online harms content. This is
            strictly for the purposes of enabling this research. Please be aware
            that this content could be offensive or triggering.
          </p>
          <div className='form-check'>
            <input
              onChange={toggleAccept}
              className='form-check-input border border-3'
              type='checkbox'
              checked={!acceptBtn}
              value=''
              id='flexCheckDefault'
            />
            <label className='form-check-label ' htmlFor='flexCheckDefault'>
              By checking this box you understand that the following contents
              could be offensive or triggering.
            </label>
          </div>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <button
          type='button'
          onClick={() => handleAcceptConsent()}
          disabled={acceptBtn}
          className='btn btn-outline-light'
        >
          Confirm
        </button>

        <button
          type='button'
          className='btn btn-outline-light'
          onClick={handleClose}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}
export default ConsentForm
