import React from 'react'
import BulletPoint from '../../../common/utils/BulletPoint'

function Examples(props) {
  let { node } = props
  return (
    <div className='col-md-6 col-sm-12 col-xs-12 mb-4'>
      <div className='item__card'>
        <div className='header__area'>
          <h3 style={{ cursor: 'default' }}> Exemplars </h3>
          <ul>
            {node.exemplars.map((item, index) => (
              <>
                <b>{item.title}</b>
                <ul style={{ marginLeft: '10px' }}>
                  {item.bullets.map((b, i) => (
                    <li key={i * index}>
                      <BulletPoint item={b} />
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Examples
