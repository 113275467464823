import React from 'react'
const HeaderFeedbackForm = () => {
  const url = process.env.REACT_APP_FEEDBACK_URL
  return (
    <a
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      className='nav-a px-2 a-dark'
      style={{ color: 'white' }}
    >
      Feedback
    </a>
  )
}
export default HeaderFeedbackForm
