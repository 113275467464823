import React, { useEffect, useRef, useState } from 'react'
import { boxedTree } from 'd3-mitch-tree'
import data from '../../../taxonomy.json'
import parse from 'html-react-parser'
import './style.css'
import EdgeCasesAndExamples from './EdgeCasesAndExamples'
import getHierarchicalData from './getHierarchicalData'
import TreeTaxonomyHeader from './treeTaxonomyHeader'
import Home from '../../../views/home'
import FeedbackForm from '../FeedbackForm'
import BreadCrumb from '../../common/breadCrumb'
import setPageCrumbs from '../../../views/setPageCrumbs'
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg'
const TreeView = ({ consentIsGiven, setConsentIsGiven }) => {
  const [nodeDefs, setNodeDefs] = useState([])
  const treeRef = useRef(null)
  const [dataSet, setTreeData] = useState([])
  const [examplesCollaped, setExampleCollapse] = useState(true)
  const [edgeCaseCollapse, setCaseCollapse] = useState(true)

  const [selectOptions, setOptions] = useState([])
  let tree
  const showHideEdgeCasesExamples = (value) => {
    if (value === 'examples') {
      if (examplesCollaped) {
        setExampleCollapse(false)
        setCaseCollapse(true)
      } else {
        setExampleCollapse(true)
      }
    } else {
      if (edgeCaseCollapse) {
        setExampleCollapse(true)
        setCaseCollapse(false)
      } else {
        setCaseCollapse(true)
      }
    }
  }
  useEffect(() => {
    consentIsGiven && setTreeData(getHierarchicalData(data))
  }, [consentIsGiven])
  const lastNodeRef = useRef(null)

  const scrollToBottom = () => {
    lastNodeRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [nodeDefs])
  useEffect(() => {
    if (dataSet.length > 0) {
      initializeTree(dataSet)
      dataSet.forEach((element) => {
        selectOptions.push({ value: element.id, label: element.name })
      })
      setOptions(selectOptions)
    }
  }, [dataSet])

  const DefaultSideContent = () => (
    <div className='def__container bg-light'>
      <div className='def__item bg-light border-0'>
        Click on the tree to explore the Online Harms Taxonomy
      </div>
    </div>
  )
  const updateDefs = (item) => {
    let node = item.nodeDataItem.data
    let child = dataSet.find((a) => a['parent-id'] === node.id)
    if (child) {
      tree.setAllowNodeCentering(true)
    } else {
      tree.setAllowNodeCentering(false)
    }

    setNodeDefs([...setPageCrumbs(node.id, dataSet)])
  }
  const initializeTree = (treeData) => {
    tree = new boxedTree({
      nodeSettings: {
        verticalSpacing: 30,
        horizontalSpacing: 100,
        sizingMode: 'nodeSize',
      },
    })
      .on('nodeClick', function (event) {
        updateDefs(event)
      })
      .setAllowZoom(false)
      .setAllowNodeCentering(true)
      .setAllowFocus(true)

      .setIsFlatData(true)
      .setData(treeData)
      .setElement(treeRef.current)
      .setIdAccessor(function (data) {
        return data.id
      })
      .setParentIdAccessor(function (data) {
        return data['parent-id']
      })
      .setBodyDisplayTextAccessor(function (data) {
        return data.name
      })

      .setTitleDisplayTextAccessor(function (data) {
        return data.name
      })
      .initialize()

    // new boxedTree({nodeSettings: {verticalSpacing: 25, horizontalSpacing: 25, sizingMode: 'nodeSize'}})
    // .on("nodeClick", function (event) {
    //   updateDefs(event);
    // })
    // .setAllowZoom(true)

    // .setAllowFocus(false)
    //  .setIsFlatData(true)
    // .setData(treeData)
    // .setElement(treeRef.current)
    // .setIdAccessor(function (data) {
    //   return data.id;
    // })
    // .setParentIdAccessor(function (data) {
    //   return data.parent;
    // })
    // .setBodyDisplayTextAccessor(function (data) {
    //   return data.name;
    // })
    // .setTitleDisplayTextAccessor(function (data) {
    //   return data.name;
    // })
    // .initialize();
  }
  const navigateTree = (id) => {
    let node = document.querySelector(`#d3plus-textBox-${id}`)
    let parentNode = node.parentElement
    let rootParent = parentNode.parentElement

    if (rootParent.fireEvent) {
      rootParent.fireEvent('onclick')
    } else {
      var evObj = document.createEvent('Events')
      evObj.initEvent('click', true, false)
      rootParent.dispatchEvent(evObj)
    }
  }

  return consentIsGiven ? (
    <div className='container-fluid tree__page'>
      <div className='row'>
        <TreeTaxonomyHeader />
        <div className='rest__btn  '>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <BreadCrumb
                  breadCrumbItems={nodeDefs}
                  navigatePage={navigateTree}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-8'>
          <div className='treeContainer text-right' ref={treeRef}></div>
        </div>
        <div
          className='col-md-4'
          style={{
            backgroundColor: 'rgb(197, 197, 197)',
            padding: 20,
            maxHeight: '70vh',
            overflow: 'auto',
          }}
        >
          {nodeDefs.length > 0 ? (
            sideBarCard(
              nodeDefs,
              lastNodeRef,
              showHideEdgeCasesExamples,
              examplesCollaped,
              edgeCaseCollapse
            )
          ) : (
            <DefaultSideContent />
          )}
        </div>
      </div>
    </div>
  ) : (
    <Home
      consentIsGiven={consentIsGiven}
      setConsentIsGiven={setConsentIsGiven}
      returnAddress='/tree'
    />
  )
}

export default TreeView
function sideBarCard(
  nodeDefs,
  lastNodeRef,
  showHideEdgeCasesExamples,
  examplesCollaped,
  edgeCaseCollapse
) {
  return (
    <div className='sidebar__item___container'>
      {nodeDefs.map(
        (def, index) =>
          def.id !== 'root' &&
          !def['hide-in-tree'] && (
            <>
              <div
                className='def__item  my-4'
                key={def.id}
                ref={index === nodeDefs.length - 1 ? lastNodeRef : null}
                style={{
                  border:
                    index !== nodeDefs.length - 1
                      ? '1px solid black'
                      : '3px solid black',
                }}
              >
                <div className='header__items d-flex'>
                  <h4>{def.name}</h4>
                  <FeedbackForm category={def.name} categories={['options']} />
                </div>
                <p>
                  {parse(
                    def['download-file-name']
                      ? `<p>${def.definition}</p>
                          To view the complete definition, click <a href='${def['download-file-name']}' download alt='download pdf'>here</a> to download a PDF of the OSDI Taxonomy. `
                      : def.definition
                  )}
                </p>
                {def['download-file-name'] ? <br /> : null}
                <EdgeCasesAndExamples
                  {...{
                    showHideEdgeCasesExamples,
                    examplesCollaped,
                    def,
                    edgeCaseCollapse,
                  }}
                />
              </div>
              {nodeDefs.length !== nodeDefs.indexOf(def) + 1 ? (
                <div className='divider__line'>
                  <Arrow />
                </div>
              ) : null}
            </>
          )
      )}
    </div>
  )
}
