import React, { useEffect, useRef } from 'react'
import BulletPoint from '../../components/common/utils/BulletPoint'
import './style.css'

const PrivacyPolicy = () => {
  const topRef = useRef(null)
  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <>
      <div class='top__header' ref={topRef}>
        <div class='container'>
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <div className='container'>
        <div className='privacy__page'>
          <div>
            <p>
              KTN is the main data holder and processor for Safety Tech
              Innovation Network.
            </p>
            <p>
              This privacy statement provides details about how your personal
              information is collected and used. Personal information is any
              information that identifies you or would enable someone to contact
              you, such as your name, email address, organisation.
            </p>
            <p>
              Please read this Policy carefully, as it sets out the basis on
              which any personal data we collect from you, or that you provide
              to us (“data”), will be processed by us, in compliance with the
              Data Protection Act 2018 (incorporating the General Data
              Protection Regulations (GDPR).
            </p>
            <p>
              <b>Your rights </b>
            </p>
            <p>
              The introduction of GDPR expands the rights of individuals to
              control how your personal information is collected and processed,
              your rights are detailed below:
            </p>
            <p>
              <ul style={{ marginLeft: 10 }}>
                <li>
                  <BulletPoint item='Right to be informed' />
                </li>
                <li>
                  <BulletPoint item='Right of access' />
                </li>
                <li>
                  <BulletPoint item='Right to rectification ' />
                </li>
                <li>
                  <BulletPoint item='Right to erasure ' />
                </li>
                <li>
                  <BulletPoint item='Right to restrict processing' />
                </li>
                <li>
                  <BulletPoint item='Right to rectification ' />
                </li>
                <li>
                  <BulletPoint item='Right to erasure ' />
                </li>
                <li>
                  <BulletPoint item='Right to restrict processing' />
                </li>
                <li>
                  <BulletPoint item='Right to data portability ' />
                </li>
                <li>
                  <BulletPoint item='Right to object ' />
                </li>
                <li>
                  <BulletPoint item='Rights related to automated decision making and profiling' />
                </li>
              </ul>
            </p>
            <p>
              <b>Information we may collect from you</b>
            </p>
            <p>
              When you access the Online Harms Taxonomy (including providing
              feedback), we will receive data about you.
            </p>
            <p>
              This data may consist of information such as your name and email
              address. Depending on the activity we may also obtain information
              about the name and type of your business.
            </p>
            <p>
              We also use cookies and collect IP addresses from visitors to our
              website.
            </p>
            <p>
              <b> What is the legal basis for doing so?</b>
            </p>
            <p>
              Article 6 (1) (a)–That the data subject has given consent to the
              processing of their personal data for one or more specific
              reasons.
            </p>
            <p>
              Article 6 (1) (b) – processing is necessary for the performance of
              a contract to which the data subject is party or in order to take
              steps at the request of the data subject prior to entering into a
              contract.
            </p>
            <p>
              Article 6 (1) (f)– processing is necessary for the purposes of the
              legitimate interests pursued by the Controller or by a third
              party, except where such interests are overridden by the interests
              or fundamental rights and freedoms of the data subject which
              require Protection of Data, in particular where the data subject
              is a child.
            </p>
            <p>
              <b>How we use your data</b>
            </p>
            <p>
              We process personal information for certain legitimate business
              purposes which include some or all of the following:
              <p>
                Where the processing enables us to enhance, modify, personalise
                or otherwise improve our services/communications for the benefit
                of our members
              </p>
              <ul style={{ marginLeft: 10 }}>
                <li>
                  <BulletPoint item='To meet our obligations to you ' />
                </li>
                <li>
                  <BulletPoint item='To provide you with our services, activities or online co' />
                  ntent and to deal with your requests and enquiries
                </li>
                <li>
                  <BulletPoint item='To identify and prevent fraud To enhance the security o' />
                  f our network and information systems
                </li>
                <li>
                  <BulletPoint item='To better understand how people interact with our website' />
                </li>
                <li>
                  <BulletPoint item='To provide e-communications which we think will be of int' />
                  erest to you
                </li>
                <li>
                  <BulletPoint item='To determine the effectiveness of our promotional camp' />
                  aigns
                </li>
              </ul>
              Whenever we process data for these purposes we will ensure that we
              always take account of your individual rights. You have the right
              to object to this processing at any time by contacting
              dataprotection@ktn-uk.org. Please bear in mind that if you object
              this may affect our ability to carry out activities as above for
              your benefit.
            </p>
            <p>
              Where we propose using your data for any purpose other than those
              listed above, we will gain explicit consent from you in advance.
            </p>
            <p>
              <b>Your personal data</b>
            </p>
            <p>
              You have the right to request from us access to your personal data
              together with details of the purpose for processing and who we
              share your data with. You can request a copy of your data held by
              contacting dataprotection@ktn-uk.org.
            </p>
            <p>
              You have the right to data portability and we will provide you
              with a copy of your automatically collected data, in a common
              format.
            </p>
            <p>
              You have the right to request that any inaccurate data is
              rectified by us.
            </p>
            <p>
              You have the right to request that your personal data is deleted
              from records where your personal data is no longer necessary in
              relation to the original purpose it was collected; where
              processing is for marketing purposes or where your personal data
              has been unlawfully processed. There are some exceptions which
              include, where we need to keep data for compliance with legal
              obligations, in defence of legal claims.
            </p>
            <p>
              You have the right to ask that we restrict the processing of your
              personal data, where you contest the accuracy or legal basis for
              processing but do not wish your data to be deleted. Please bear in
              mind that if you object this may affect our ability to carry out
              activities as above for your benefit.
            </p>
            <p>
              <b>Retention & Deletion</b>
            </p>
            <p>
              We shall not keep data any longer than is necessary from the
              original purpose of collection and we will delete data in line
              with our Data Retention policy in compliance with our legal
              obligations.
            </p>
            <p>
              <b>IP address and cookies </b>
            </p>
            <p>
              An IP address is a number that can uniquely identify a specific
              computer or other network device on the internet. We may collect
              information about your computer, including your IP address,
              operating system and browser type, for system administration. This
              is statistical data about our users’ browsing actions and
              patterns, and does not identify any individual.
            </p>
            <p>
              We use Google Analytics software to collect anonymised information
              about how you use GOV.UK. We do this to help make sure the site is
              meeting the needs of its users and to help us make improvements to
              the site and to government digital services.
            </p>
            <p>
              We do not allow Google to use or share the data about how you use
              this site. Google Analytics stores information about:
              <ul style={{ marginLeft: 10 }}>
                <li>
                  <BulletPoint item='how you got to the site' />
                </li>
                <li>
                  <BulletPoint item='the pages you visit on safetytechnetwork.org.uk and how long you spend on them' />
                </li>
                <li>
                  <BulletPoint item=' what you click on while you’re visiting the site' />
                </li>
              </ul>
            </p>
            <p>
              <b>Cookie:</b> Cookie preference
            </p>
            <p>
              <b>Name: </b>CookieControl
            </p>
            <p>
              <b>Purpose: </b>This cookie is used to remember a user’s choice
              about cookies. Where users have previously indicated a preference,
              that user’s preference will be stored in this cookie.
            </p>
            <p>
              <b>Cookie: </b>Universal Analytics (Google)
            </p>
            <p>
              <b>Name:</b> _ga _gali _gat _gid
              <p>
                <b>Purpose: </b>These cookies are used to collect information
                about how visitors use our website. We use the information to
                compile reports and to help us improve the website. The cookies
                collect information in a way that does not directly identify
                anyone, including the number of visitors to the website and
                blog, where visitors have come to the website from and the pages
                they visited. Read Google’s overview of privacy and safeguarding
                data
              </p>
              <p>
                <b>Cookie: </b>Vimeo cookies
              </p>
              <p>
                <b> Name:</b> player vuid
              </p>
              <b>Purpose:</b> We embed videos from our official Vimeo channel.
              When you press play, Vimeo will drop third party cookies to enable
              the video to play and to collect analytics data, such as how long
              a viewer has watched the video. These cookies do not track
              individuals.
            </p>
            <p>
              <b> Disclosure of your data to third parties</b>
            </p>
            <p>
              KTN as the data controller will not disclose your data to any
              third parties.
            </p>
            <p>
              <b>Where we store your personal data</b>
            </p>
            <p>
              All personal data you provide to us is stored on secure servers,
              which are operated in accordance with the UK Information
              Commissioner’s guidelines on the storage of personal data.
            </p>
            <p>
              We cannot guarantee the security of your data during transmission
              from you to us, and you transmit such data at your own risk. Once
              your data has reached our servers, we will use strict procedures
              and security features to try and prevent unauthorised access.
            </p>
            <p>
              <b>International Transfer of Data (outside EEA)</b>
            </p>
            <p>
              We will only transfer data to countries outside of the EEA where
              they comply with the appropriate safeguards for processing
              personal data as approved by the European Commission in line with
              GDPR.
            </p>
            <p>
              <b>Changes to our Privacy Policy</b>
            </p>
            <p>
              Any changes we may make to this Policy will be posted on this page
              and, where appropriate, notified to you by e-mail.
            </p>
            <p>
              <b>Data Protection Officer </b>
            </p>
            <p>
              Any questions or requests regarding this Policy and/or the
              processing of your data are welcomed, and should be addressed to
              dataprotection@ktn-uk.org
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
