import React from 'react'
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BulletPoint from '../../common/utils/BulletPoint'

const EdgeCasesAndExamples = ({
  showHideEdgeCasesExamples,
  examplesCollaped,
  def,
  edgeCaseCollapse,
}) => {
  return (
    <>
      {(def['edgcases'] && def['edgcases'].length) ||
      (def.exemplars && def.exemplars.length) ? (
        <div className='row'>
          <div className='col'>
            {examplesCollaped ? (
              <FontAwesomeIcon
                icon={faPlusSquare}
                onClick={() => showHideEdgeCasesExamples('examples')}
                style={{ paddingRight: 5 }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faMinusSquare}
                onClick={() => showHideEdgeCasesExamples('examples')}
                style={{ paddingRight: 5 }}
              />
            )}
            <span>Exemplars</span>
          </div>
          <div className='col'>
            {edgeCaseCollapse ? (
              <FontAwesomeIcon
                icon={faPlusSquare}
                onClick={() => showHideEdgeCasesExamples('')}
                style={{ paddingRight: 5 }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faMinusSquare}
                onClick={() => showHideEdgeCasesExamples('')}
                style={{ paddingRight: 5 }}
              />
            )}
            <span>Edge Cases</span>
          </div>
        </div>
      ) : null}
      <div>
        {!examplesCollaped &&
          def.exemplars.map((item) => (
            <>
              <div key={item.title}>{item.title}</div>
              <ul style={{ marginLeft: '10px' }}>
                {item.bullets.map((b) => (
                  <li>
                    <BulletPoint item={b} />
                  </li>
                ))}
              </ul>
            </>
          ))}
      </div>
      <div>
        {!edgeCaseCollapse &&
          def['edge-cases'].map((item) => (
            <>
              <div key={item.title}>{item.title}</div>
              <ul style={{ marginLeft: '10px' }}>
                {item.bullets.map((b) => (
                  <li>
                    <BulletPoint item={b} />
                  </li>
                ))}
              </ul>
            </>
          ))}
      </div>
    </>
  )
}

export default EdgeCasesAndExamples
