import React from 'react'
import { Link } from 'react-router-dom'

import footer1 from '../../../assets/images/footer1.png'
import footer2 from '../../../assets/images/footer2.png'
import footer3 from '../../../assets/images/footer3.png'
import footer4 from '../../../assets/images/footer4.png'
import './style.css'
function Footer() {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='main__content'>
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: 'black',
            }}
          >
            <div>
              <Link to='/privacypolicy' className='footer__link' style={{ color: 'white' }}>
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link to='/terms-conditions' className='footer__link' style={{ color: 'white' }}>
                Terms of use
              </Link>
            </div>
          </div>
          <div className='items'>
            <div className='item'>
              <img src={footer1} alt='' />
            </div>
            <div className='item'>
              <img src={footer2} alt='' />
            </div>
            <div className='item'>
              <img src={footer3} alt='' />
            </div>
            <div className='item'>
              <img src={footer4} alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
