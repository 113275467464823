import React from 'react'
import { ReactComponent as RefreshIcon } from '../../../assets/images/refresh.svg'

const BreadCrumb = (props) => {
  let { breadCrumbItems, navigatePage } = props

  return (
    <div className='col-12 bread__crumb__area'>
      {breadCrumbItems.length > 0 ? (
        <ul className='bread__crumb__container'>
          <li
            className='fw-bold page__reset_button'
            onClick={() => navigatePage('root')}
          >
            <RefreshIcon />
            <span> Reset</span>
          </li>

          {breadCrumbItems.map((item) => (
            <React.Fragment key={item.id}>
              <li
                onClick={() => navigatePage(item.id)}
                className='bread__crumb__item'
              >
                {item.name}
              </li>
              {breadCrumbItems.length !== breadCrumbItems.indexOf(item) + 1 ? (
                <li> /</li>
              ) : null}
            </React.Fragment>
          ))}
        </ul>
      ) : (
        <ul className='bread__crumb__container'>
          <li
            className='fw-bold page__reset_button'
            onClick={() => navigatePage('root')}
          >
            <RefreshIcon />
            <span> Reset</span>
          </li>
        </ul>
      )}
    </div>
  )
}

export default BreadCrumb
