import React from 'react'
import TreeView from '../../components/page/treeview'
function TreePage({ consentIsGiven, setConsentIsGiven }) {
  return (
    <div className='container'>
      <div className='page__container'>
        <TreeView {...{ consentIsGiven, setConsentIsGiven }} />
      </div>
    </div>
  )
}

export default TreePage
