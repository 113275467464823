const getHierarchicalData = (data) => {
  function removeFromTree(data, node, parent, newTree) {
    const removed =
      (node['hide-in-tree'] && node['hide-children-in-tree']) ||
      (parent && parent.removed)
    newTree.push({
      ...node,
      removed: removed,
    })
    data
      .filter((x) => x['parent-id'] === node.id)
      .map((c) =>
        removeFromTree(data, c, { ...node, removed: removed }, newTree)
      )
  }
  var newTree = []
  removeFromTree(
    data,
    data.find((x) => x.id === 'root'),
    null,
    newTree
  )

  //this is where API should be called
  let apiCall = newTree.filter((x) => !x.removed)
  let newSet = []
  apiCall.forEach((item) => {
    if (item['hide-in-tree'] === true) {
      let children = newTree.filter((a) => a['parent-id'] === item.id)
      children.forEach((child) => {
        child['parent-id'] = item['parent-id']
        let test = newSet.find((a) => a.id === child.id)
        if (!test) {
          newSet = [...newSet, child]
        }
      })
    } else {
      let test = newSet.find((a) => a.id === item.id)
      if (!test) {
        newSet = [...newSet, item]
      }
    }
  })

  return newSet
}
export default getHierarchicalData
