import React from "react";
import parser from "html-react-parser";

import { ReactComponent as DownloadIcon } from "../../../../assets/images/download.svg";
import data from "../../../../taxonomy.json";
function TaxonomyHeader(props) {
  let { node, firstLevel } = props;
  const createDownloadLink = async () => {
    let textToSave = JSON.stringify(data);

    let hiddenElement = document.createElement("a");

    hiddenElement.href = "data:attachment/text," + encodeURI(textToSave);
    hiddenElement.target = "_blank";
    hiddenElement.download = "OSDI-Taxonomy.json";
    hiddenElement.click();
  };
  return (
    <div className="full__taxonomy__header__area">
      <div className="container ">
        <div className="row">
          <div className="col12">
            <div className="header__area page__header__area">
              {firstLevel ? (
                <h1>Welcome to the OSDI Taxonomy</h1>
              ) : (
                <h1>{node.name}</h1>
              )}

              {firstLevel ? (
                <h2>
                  A resource for all those dedicated to a safer world online{" "}
                </h2>
              ) : null}
              {firstLevel ? <small>Last updated: 06/04/2022</small> : null}
              {firstLevel ? (
                <p>
                  The Online Harms Taxonomy is a working, functionally useful
                  classification schema for defining, categorising, and
                  sub-categorising online harms. Here you can access the OSDI
                  Taxonomy in a table format or download the table in PDF and
                  JSON formats to apply the OSDI Taxonomy to your own data. Some
                  online harms use an abridged definition, a full list of
                  definitions, citations and other materials can be accessed
                  through the downloadable PDF link below.
                </p>
              ) : (
                <p>
                  {parser(
                    node["download-file-name"]
                      ? `${node.definition}</p> To view the complete definition, click <a href='${node["download-file-name"]}' download alt='download pdf'>here</a> to download a PDF of the OSDI Taxonomy.`
                      : node.definition
                  )}
                </p>
              )}

              {firstLevel ? (
                <div className="btn__area__container">
                  <button
                    onClick={createDownloadLink}
                    className="btn first__btn"
                  >
                    <DownloadIcon />
                    <span>JSON</span>
                  </button>
                  <a
                    href={`${process.env.PUBLIC_URL}/ctfoh.pdf`}
                    download="ctfoh.pdf"
                    className="btn  second__btn"
                  >
                    <DownloadIcon />
                    <span> Common taxonomy for online harms</span>
                  </a>
                  <a
                    href={`${process.env.PUBLIC_URL}/abh.pdf`}
                    download="abh.pdf"
                    className="btn  second__btn "
                  >
                    <DownloadIcon />
                    <span> Abuse, bullying and harassment</span>
                  </a>
                  <a
                    href={`${process.env.PUBLIC_URL}/hate.pdf`}
                    download="hate.pdf"
                    className="btn  second__btn "
                  >
                    <DownloadIcon />
                    <span> Hate</span>
                  </a>
                  <a
                    href={`${process.env.PUBLIC_URL}/ssh.pdf`}
                    download="ssh.pdf"
                    className="btn  second__btn "
                  >
                    <DownloadIcon />
                    <span> Suicide and self-harm</span>
                  </a>
                </div>
              ) : (
                <div className="btn__area__container"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxonomyHeader;
