import React from 'react'

function TreeTaxonomyHeader(props) {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='header__area page__header__area'>
          <h1>Welcome to the OSDI Taxonomy</h1>
          <h2>A resource for all those dedicated to a safer world online</h2>
          <small>Last updated: 06/04/2022</small>
        </div>
      </div>
    </div>
  )
}

export default TreeTaxonomyHeader
