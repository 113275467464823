import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import DepartmentLogo from "../../assets/images/logo_department.png";
import Oista from "../../assets/images/logo_ostia.png";
import PublicLogo from "../../assets/images/logo_public.png";

import Faculty from "../../assets/images/logo_faculty.png";

import Orn200 from "../../assets/images/orn_200.png";

import RightArrow from "../../assets/images/orn_r_arrow.png";
import BulletPoint from "../../components/common/utils/BulletPoint";

function AboutUs() {
  const feedbackUrl = process.env.REACT_APP_FEEDBACK_URL;

  return (
    <>
      <div class="top__header">
        <div class="container">
          <h4>About Us</h4>
        </div>
      </div>

      <div class="hero__top__box">
        <div class="container">
          <div class="content">
            <h4>Who produced the Online Harms Taxonomy?</h4>
            <p>
              The Online Harms Taxonomy was delivered by the Online Safety Data
              Initiative (OSDI). Led by a consortium of experts from the
              Department of Digital, Culture, Media and Sport (DCMS) and wider
              UK Government, the Online Safety Tech Industry Association
              (OSTIA), Faculty and PUBLIC, OSDI supports the development of
              technology to identify and remove harmful and illegal content from
              the internet.
            </p>

            <ul>
              <li>
                <a href="https://www.gov.uk/government/organisations/department-for-digital-culture-media-sport">
                  <img
                    src={DepartmentLogo}
                    draggable={false}
                    alt="Logo for Department for Digital, Culture, Media and Sport"
                  />
                </a>
              </li>
              <li>
                <a href="https://ostia.org.uk/">
                  <img
                    draggable={false}
                    src={Oista}
                    alt="Logo for Online Safety Tech Industry Association"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.public.io/">
                  <img
                    draggable={false}
                    src={PublicLogo}
                    alt="Logo for Public, a technology company"
                  />
                </a>
              </li>
              <li>
                <a href="https://faculty.ai/">
                  <img
                    draggable={false}
                    src={Faculty}
                    alt="Logo for Faculty, an artificial intelligence company"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="taxonomy__develop">
        <div class="container">
          <div class="content">
            <div class="left">
              <h4>How was the Online Harms Taxonomy developed?</h4>
              <p>
                Through 200+ research interviews, roundtables and workshops with
                industry, academia and civil society, OSDI identified the huge
                variance in how online harms are classified as a key barrier to
                the growth of the Safety Tech sector. The lack of a common
                understanding makes it challenging for data to be labelled and
                stored in a way that is consistent enough to establish greater
                confidence in dataset sharing practices. In response, OSDI has
                developed the Online Harms Taxonomy, a working, functionally
                useful classification schema for defining, categorising, and
                sub-categorising a number of online harms.
              </p>
            </div>

            <div class="right">
              <img
                src={Orn200}
                draggable={false}
                alt="mage with an icon showing that 200 plus safety tech community interviews have been conducted"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="who__is__it__for">
        <div class="container">
          <div class="content">
            <h4>What is the Online Harms Taxonomy and who is it for?</h4>

            <div class="items">
              <div class="item">
                <h5>Research</h5>
                <p>
                  The OSDI Taxonomy is grounded in the latest online harms
                  research and extensive engagement with OSTIA and its members.
                  Existing work is leveraged to develop a common language for
                  describing online harms within the sector.
                </p>
              </div>

              <div class="spacer">
                <img
                  draggable={false}
                  src={RightArrow}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  alt="a right arrow"
                />
              </div>

              <div class="item">
                <h5>Modelling</h5>
                <p>
                  OSDI’s analysis was modelled to consider the complexities
                  involved, including how harms can be grouped; legal
                  definitions of online harms; age-specific issues; risk
                  factors; and assessing how harmful content and activity
                  affects victims.
                </p>
              </div>

              <div class="spacer">
                <img
                  draggable={false}
                  src={RightArrow}
                  alt="a right arrow"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
              </div>

              <div class="item">
                <h5>Adoption</h5>
                <p>
                  The development of this approach to creating shared
                  definitions for online harms represents a step forward for the
                  sector. However adoption rests on long-term collaboration to
                  deliver real and meaningful impact to the sector.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contribute">
        <div class="container">
          <div class="content">
            <h4>How can I contribute to the Online Harms Taxonomy?</h4>
            <p>
              Complete documentation, including fully detailed definitions,
              commentary and sources are available to download from the links
              below as PDFs:
            </p>
            <p>
              <ul>
                <li>
                  <a download href={`${process.env.PUBLIC_URL}/ctfoh.pdf`}>
                    <BulletPoint item={"A common taxonomy for online harms"} />
                  </a>
                </li>
                <li>
                  <a download href={`${process.env.PUBLIC_URL}/abh.pdf`}>
                    <BulletPoint item={"Abuse, bullying and harassment"} />
                  </a>
                </li>
                <li>
                  <a download href={`${process.env.PUBLIC_URL}/hate.pdf`}>
                    <BulletPoint item={"Hate"} />
                  </a>
                </li>
                <li>
                  <a download href={`${process.env.PUBLIC_URL}/ssh.pdf`}>
                    <BulletPoint item={"Suicide and self-harm"} />
                  </a>
                </li>
              </ul>
            </p>
            <p>
              We encourage users to provide feedback on online harm definitions.
              The OSDI Taxonomy will be regularly updated, based on ongoing
              research and feedback received directly on this website.
            </p>
            <a
              href={feedbackUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="nav-a px-2 a-dark feedback"
            >
              <b> Provide Feedback</b>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
