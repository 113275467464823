const setPageCrumbs = (id, data) => {
  let items = []
  if (id !== 'root') {
    let last = data.find((item) => item.id === id)
    items.push(last)
    let lastId = last.id

    while (lastId !== 'root') {
      let node = data.find((a) => a.id === last['parent-id'])
      if (node.id !== 'root') {
        items.push(node)
        last = node
      }
      lastId = node.id
    }
    items.reverse()
  }

  return [...items]
}
export default setPageCrumbs
