import React, { useState, useEffect, useLayoutEffect } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import HeaderFeedbackForm from './HeaderFeedbackForm'
import './style.css'

function Header({ consentIsGiven, setConsentIsGiven }) {
  const [mobileMode, setMobileMode] = useState(false)
  const [isOpen, setOpen] = useState(false)
  let location = useLocation()
  const [width, height] = useWindowSize()

  useEffect(() => {
    if (width < 968) {
      setMobileMode(true)
      setOpen(false)
    } else {
      setMobileMode(false)
      setOpen(true)
    }
  }, [location, width])
  return (
    <div className='App-header '>
      <div className='container d-flex header__container'>
        <div className='image__logo'>
          <span>
            <a href='https://www.safetytechnetwork.org.uk/' title='STIN'>
              <Logo />
            </a>
          </span>
        </div>
        {!mobileMode ? (
          <ul className='d-flex   col-12 col-md-auto mb-2 mb-md-0 nav__item__container'>
            <li>
              <Link to='/' className='nav-a px-2 a-secondary'>
                Home
              </Link>
            </li>

            {consentIsGiven && (
              <li>
                <Link to='/tree' className='nav-a px-2 a-dark'>
                  OSDI Taxonomy Tree
                </Link>
              </li>
            )}
            {consentIsGiven && (
              <li>
                <Link to='/fulltaxonomytable' className='nav-a px-2 a-dark'>
                  OSDI Taxonomy Table
                </Link>
              </li>
            )}
            <li>
              <Link to='/about-us' className='nav-a px-2 a-dark'>
                About
              </Link>
            </li>

            <li>
              <HeaderFeedbackForm />
            </li>
          </ul>
        ) : (
          <Menu isOpen={isOpen}>
            <Link className='menu-item' to='/' onClick={() => setOpen(true)}>
              Home
            </Link>
            <Link
              className='menu-item'
              to='/about-us'
              onClick={() => setOpen(true)}
            >
              About
            </Link>
            {consentIsGiven && (
              <Link
                className='menu-item'
                to='/fulltaxonomytable'
                onClick={() => setOpen(true)}
              >
                OSDI Taxonomy Table
              </Link>
            )}
            <Link
              className='menu-item'
              to={process.env.REACT_APP_FEEDBACK_URL}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => setOpen(true)}
            >
              <HeaderFeedbackForm />
            </Link>
          </Menu>
        )}
      </div>
    </div>
  )
}
function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
export default Header
