import React from 'react'
import { ReactComponent as MegaPhone } from '../../../assets/images/megaphone.svg'
const FeedbackForm = ({ category, categories }) => {
  const cat = category ? `?category=${category}` : ''
  const options = cat
    ? `&options=${JSON.stringify(categories)}`
    : categories
    ? `?options=${JSON.stringify(categories)}`
    : ''
  const url = `${process.env.REACT_APP_FEEDBACK_URL}${cat}${options}`
  return (
    <a
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      className='nav-a px-2 a-dark'
    >
      <span className='feedback__btn'>
        <MegaPhone />
        Feedback <hr />
      </span>
    </a>
  )
}
export default FeedbackForm
