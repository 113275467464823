import React, { useState, useEffect } from 'react'
import parser from 'html-react-parser'
import FeedbackForm from '../../FeedbackForm'

function TaxonomyCard(props) {
  let { node } = props
  const [nodeDef, setNodeDef] = useState('')
  useEffect(() => {
    getNodedefinition()
  }, [])
  const getNodedefinition = () => {
    let lessDef = node.definition
    if (lessDef.length > 250 && !node['hide-in-tree']) {
      const first250Chars = lessDef.slice(0, 250)
      let lastIndex = first250Chars.lastIndexOf(' ')
      if (lastIndex === first250Chars.lastIndexOf(' /')) {
        lastIndex = lastIndex - 3
      }
      lessDef = lessDef.slice(0, lastIndex) + '...'
    }
    if (node['download-file-name']) {
      lessDef = `<p>${lessDef}</p> To view the complete definition, click <a href='${node['download-file-name']}' download alt='download pdf'>here</a> to download a PDF of the OSDI Taxonomy.<p>`
    }
    setNodeDef(parser(lessDef))
  }

  const showDefinition = () => {
    !node['hide-in-tree'] && props.onpageChang(node.id)
  }

  return (
    <div
      className={
        node['hide-in-tree']
          ? 'col-md-12 col-sm-12 col-xs-12 mb-4'
          : 'col-md-6 col-sm-12 col-xs-12 mb-4'
      }
    >
      <div
        className={
          !node['hide-in-tree'] ? `item__card ` : 'item__card special__card'
        }
      >
        <div className='header__area'>
          <div className='card__header d-flex'>
            {node['hide-in-tree'] ? (
              <>
                <h3 onClick={() => showDefinition()}>{node.name}</h3>
                <FeedbackForm category={node.name} categories={['options']} />
              </>
            ) : (
              <>
                <h3 onClick={() => showDefinition()}> {node.name} </h3>
                <FeedbackForm category={node.name} categories={['options']} />
              </>
            )}
          </div>
          <p>{nodeDef}</p>
          {!node['hide-in-tree'] && (
            <>
              <div className='btn__area__container'>
                <button
                  onClick={() => showDefinition()}
                  className='btn btn-dark second__btn'
                >
                  <span>More detail</span>
                </button>
              </div>
            </>
          )}
          {/* <small>User Viewing</small> */}
        </div>
      </div>
    </div>
  )
}

export default TaxonomyCard
